import { BrowserRouter } from "react-router-dom";
import Navbar from "./Layout/navbar";
import { Map } from "./Map/Map";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <Map />
      </BrowserRouter>
    </div>
  );
}

export default App;
