import ProjectMetaDataCard from "./ProjectMetaDataCard";

export default function ProjectCard({ data, setProjectId }) {
  return (
    <div
      className="mt-8 flex flex-col xl:flex-row items-center gap-6 p-4 cursor-pointer hover:bg-neutral-100 hover:rounded-xl"
      onClick={() => {
        setProjectId(data.properties.id);
      }}
    >
      <img
        className="aspect-[1] w-[260px] h-[248px] object-cover rounded-lg"
        src={data.properties.image}
      />

      <div className="w-full">
        <p>
          <span className="text-light-green uppercase">
            {data.properties.type}
          </span>
        </p>

        <h3 className="font-fraunces font-weight-600 text-[24px] line-clamp-1">
          {data.properties.title}
        </h3>

        <p>
          <span className="text-neutral-700">{data.properties.address}</span>
        </p>

        <p className="mt-4 line-clamp-3 text-neutral-700	leading-6">
          {data.properties.description}
        </p>

        <div className="flex gap-6 mt-6">
          <ProjectMetaDataCard
            meta="Project Size"
            value={data.properties.projectSize}
          />
          <ProjectMetaDataCard
            meta="Beneficiaries"
            value={data.properties.beneficiaries}
          />
        </div>
      </div>
    </div>
  );
}
