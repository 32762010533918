import { ArrowLeft, MapPin } from "react-feather";
import ProjectMetaDataCard from "../../components/ProjectMetaDataCard";

export default function ProjectDetails({ cn, data, clearProjectId }) {
  return (
    <div className={`${cn} px-2 lg:px-12 pt-10`}>
      <div
        className="flex text-neutral-700 gap-4 cursor-pointer"
        onClick={clearProjectId}
      >
        <ArrowLeft className="" />
        <p className="font-semibold font-[18px]">Back to Projects</p>
      </div>

      {!data ? (
        <div className="mt-8 text-center">
          <p className="text-neutral-700">Select a project to view details</p>
        </div>
      ) : (
        <div>
          <div className="my-8">
            <img
              src={data?.properties?.image}
              alt="projectImage"
              className="w-full h-[342px] object-cover rounded-2xl"
            />
          </div>

          <div className="mt-8 flex flex-col gap-4">
            <p className="text-light-green uppercase text-[18px] ">
              {data?.properties.type}
            </p>

            <h4 className="font-fup text-dark-green text-[32px] font-semibold ">
              {data?.properties?.title}
            </h4>
            <p className="flex items-center gap-2 text-[#798C8E]">
              <MapPin className="text-light-green" />
              <span>{data?.properties?.address}</span>
            </p>
          </div>

          <div className="mt-8 flex flex-col gap-4">
            <h6 className="font-fup text-[24px] text-dark-green">
              About Project
            </h6>
            <p className="text-neutral-700">{data?.properties?.description}</p>
          </div>

          <div className="flex flex-wrap lg:flex-nowrap gap-6 mt-8">
            <ProjectMetaDataCard
              meta="Project Size"
              value={data?.properties?.projectSize}
            />
            <ProjectMetaDataCard
              meta="Beneficiaries"
              value={data?.properties?.beneficiaries}
            />
          </div>

          <div className="my-8">
            <h6 className="font-fup text-[24px] text-dark-green">
              Fields{" "}
              <span className="text-light-green ml-4">
                {" "}
                {data?.fields?.length}{" "}
              </span>
            </h6>

            <div className="flex flex-col mt-4 gap-4">
              {data?.fields?.map((field, index) => (
                <div
                  className="flex justify-between p-4 rounded-xl bg-neutral-100"
                  key={index}
                >
                  <div className="text-light-green font-semibold">
                    {data.properties.title}, Field #{index + 1}
                  </div>
                  <div className="text-neutral-700">{field.area}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
