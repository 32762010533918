import Logo from "../assets/logo.png";

export default function Navbar() {
  return (
    <div className="flex justify-between items-center px-10 py-4 border-b-2 border-[#D3E2E3] cursor-pointer">
      <img
        src={Logo}
        onClick={() => {
          window.location.href = "https://bhumi.earth/";
        }}
      />
      <button
        className="hidden sm:flex px-3 py-[10px] bg-[#2BB24B] text-white rounded-full"
        onClick={() => {
          window.location.href = "https://bhumi.earth/#contact";
        }}
      >
        Get in Touch{" "}
      </button>
    </div>
  );
}
