export const mapData = {
  data: [
    {
      type: "Feature",
      properties: {
        id: "1",
        title: "Reforestation at Lunkhu Deurali",
        type: "Forest Carbon",
        description:
          "Lunkhu Deurali- a beautiful gurung village at Mahashila Rural Municipality, Parbat, Nepal is facing a mass exodus of villagers leaving the land barren. The land used to pay back a lot before but now it's hard for villagers to live off the land. Our project at Lunkhu Deurali will empower villagers to invest in reforestation and perennial plantations to get benefitted from carbon credits.",
        projectSize: "20Ha",
        projectType: "ARR",
        beneficiaries: "200+ smallholder farmers",
        address: "Mahashila Rural Municipality, Parbat, Nepal",
        image:
          "https://race-dev.blr1.digitaloceanspaces.com/bhumi.earth/static_images/ld.jpg",
      },
      fields: [
        {
          area: "85639.11m²",
          coordinates: [
            [83.675339743, 28.118989749],
            [83.675010502, 28.119596235],
            [83.674543463, 28.119728118],
            [83.674032837, 28.120146535],
            [83.673133962, 28.120799144],
            [83.672183119, 28.121009386],
            [83.671086431, 28.121938761],
            [83.670659959, 28.122763749],
            [83.670134246, 28.122662622],
            [83.669734932, 28.121373981],
            [83.670646548, 28.120642128],
            [83.671195395, 28.120454951],
            [83.671689257, 28.120160138],
            [83.672443964, 28.119873308],
            [83.67308937, 28.119359969],
            [83.673561774, 28.118983835],
            [83.673870899, 28.118464282],
            [83.674158566, 28.118127769],
            [83.674560897, 28.117896231],
            [83.67488645, 28.118489712],
            [83.675339743, 28.118989749],
          ],
        },
        {
          area: "1424.45m²",
          coordinates: [
            [83.67693767, 28.120210111],
            [83.677201197, 28.120120218],
            [83.67693767, 28.119920324],
            [83.676718734, 28.119778388],
            [83.676609434, 28.119784302],
            [83.676625527, 28.119938658],
            [83.676718399, 28.120034465],
            [83.67674455, 28.120168713],
            [83.67682904, 28.120257719],
            [83.67693767, 28.120210111],
          ],
        },
        {
          area: "2536.5m²",
          coordinates: [
            [83.676297292, 28.120685892],
            [83.676521257, 28.120333418],
            [83.676090091, 28.120095083],
            [83.676528633, 28.119915593],
            [83.676517569, 28.119618709],
            [83.676055558, 28.119679623],
            [83.675989844, 28.120544548],
            [83.676297292, 28.120685892],
          ],
        },
        {
          area: "2553.67m²",
          coordinates: [
            [83.676684871, 28.119509299],
            [83.677403368, 28.119095906],
            [83.677854985, 28.119488895],
            [83.677628338, 28.120045406],
            [83.676684871, 28.119509299],
          ],
        },
        {
          area: "1405.81m²",
          coordinates: [
            [83.67409654, 28.121289412],
            [83.673893027, 28.121263982],
            [83.673774004, 28.121276105],
            [83.673671745, 28.121274923],
            [83.673657998, 28.121216375],
            [83.673673421, 28.121137719],
            [83.673765957, 28.121034225],
            [83.673798479, 28.120975676],
            [83.673864193, 28.120908257],
            [83.674155548, 28.121049601],
            [83.674185723, 28.121180891],
            [83.67409654, 28.121289412],
          ],
        },
        {
          area: "2315.88m²",
          coordinates: [
            [83.67623426, 28.121917176],
            [83.676229902, 28.121845026],
            [83.676117919, 28.121703092],
            [83.676077686, 28.121570324],
            [83.676092438, 28.121409761],
            [83.675983809, 28.121202181],
            [83.675988838, 28.121037773],
            [83.676035106, 28.120919789],
            [83.675860763, 28.12090737],
            [83.675781637, 28.120949064],
            [83.675847352, 28.121152208],
            [83.675833605, 28.121333766],
            [83.675875179, 28.121591614],
            [83.675965704, 28.121801263],
            [83.67607031, 28.121896181],
            [83.676030077, 28.122071233],
            [83.676118925, 28.122141313],
            [83.67623426, 28.121917176],
          ],
        },
      ],
    },
    {
      type: "Feature",
      properties: {
        id: "7",
        title: "NEP-Aadhikhola Food Forest",
        type: "Soil Carbon + Forest Carbon",
        description:
          "This project is situated in Andhikhola Rural Municipality, Syangja. The aim of this project is to integrate high value fruit and tree species to develop a food forest. The main products are mandarin, coffee, millet, beans and aim to provide market access to high value agroforestry products along with issuance of carbon and biodiversity credits. Currently the project has 300+ mandarin plants and 200+ lemon plants. The project is expected to add more plants to this site.",
        projectSize: "3Ha",
        projectType: "ARR",
        beneficiaries: "50+ smallholder farmers",
        address: "Aandhikhola Rural Municipality-02, Syangja, Nepal",
        image:
          "https://race-dev.blr1.digitaloceanspaces.com/bhumi.earth/static_images/aad.jpeg",
      },
      fields: [
        {
          area: "16055.17m²",
          coordinates: [
            [83.800541237, 28.162815314],
            [83.800764531, 28.162929704],
            [83.800991178, 28.163070992],
            [83.801189661, 28.163081633],
            [83.801250681, 28.163195432],
            [83.801346906, 28.163312778],
            [83.801378757, 28.163730434],
            [83.801107518, 28.163907191],
            [83.800886236, 28.163921674],
            [83.800696135, 28.164043749],
            [83.800346777, 28.164210751],
            [83.800326996, 28.164524951],
            [83.800026588, 28.164067691],
            [83.799740933, 28.163963351],
            [83.799771108, 28.163711812],
            [83.799965233, 28.163494856],
            [83.800160028, 28.163155825],
            [83.800541237, 28.162815314],
          ],
        },
      ],
    },
  ],
};
