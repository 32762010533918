import ProjectCard from "../../components/ProjectCard";

export function Projects({ cn, data, setProjectId }) {
  return (
    <div className={`${cn} px-2 lg:px-12`}>
      <div
        className={` flex gap-4 font-fraunces mt-10 text-[32px] font-weight-600`}
      >
        <h1 className="">Projects</h1>
        <p className="text-light-green">{data?.features?.length}</p>
      </div>
      <div className="my-8 border"></div>

      {data?.features?.map((feature, index) => (
        <ProjectCard key={index} data={feature} setProjectId={setProjectId} />
      ))}
    </div>
  );
}
