import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import pin from "../assets/pin.png";
import { mapData } from "./mapdata";
import ProjectDetails from "./sidebar/ProjectDetails";
import { Projects } from "./sidebar/Projects";

mapboxgl.accessToken = process.env.REACT_APP_MAP_API_KEY;

let mapDataPolygons = {
  type: "geojson",
  data: {
    type: "FeatureCollection",
    features: mapData.data.map((item) => {
      // return item.fields.map((data) => {
      return {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [item.fields[0].coordinates],
        },
        properties: {
          area: item.fields[0].area,
        },
      };
      // });
    }),
    // ),
  },
};

let mapDataPolygonsOtherFields = {
  type: "geojson",
  data: {
    type: "FeatureCollection",
    features: new Object(
      ...mapData.data.map((item) => {
        let projectName = item.properties.title;
        return item.fields.reduce((acc = [], curr, index) => {
          if (!index) return acc;
          acc.push({
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: [curr.coordinates],
            },
            properties: {
              projectName,
              id: index + 1,
              name: curr.name,
              area: curr.area,
            },
          });
          return acc;
        }, []);
      })
    ),
  },
};

let serializedMapData = {
  type: "FeatureCollection",
  crs: {
    type: "name",
    properties: {
      name: "urn:ogc:def:crs:OGC:1.3:CRS84",
    },
  },

  features: mapData?.data?.map((item) => {
    return {
      ...item,
      geometry: {
        type: "Point",
        coordinates: item.fields?.[0].coordinates.reduce(
          (acc = [], curr, cIndex, array) => {
            acc[0] += curr[0];
            acc[1] += curr[1];
            if (cIndex === array.length - 1) {
              acc[0] /= array.length;
              acc[1] /= array.length;
            }
            return acc;
          },
          [0, 0]
        ),
      },
      bounds: [
        [
          Math.min(
            ...item.fields.map((i) =>
              Math.min(...i.coordinates.map((c) => c[0]))
            )
          ),
          Math.min(
            ...item.fields.map((i) =>
              Math.min(...i.coordinates.map((c) => c[1]))
            )
          ),
        ],
        [
          Math.max(
            ...item.fields.map((i) =>
              Math.max(...i.coordinates.map((c) => c[0]))
            )
          ),
          Math.max(
            ...item.fields.map((i) =>
              Math.max(...i.coordinates.map((c) => c[1]))
            )
          ),
        ],
      ],
    };
  }),
};

export function Map() {
  const mapContainer = useRef(null);
  let [searchParams, setSearchParams] = useSearchParams();
  const [selectedData, setSelectedData] = useState(null);

  const map = useRef(null);

  useEffect(() => {
    if (map.current) return;
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/satellite-streets-v12",
      projection: "mercator",
      center: [83, 28],
      zoom: 8,
    });

    map.current.on("load", () => {
      map.current.addSource("circles", {
        type: "geojson",
        data: serializedMapData,
        cluster: true,
        clusterMaxZoom: 4,
        clusterRadius: 50,
      });

      // clusters
      map.current.addLayer({
        id: "clusters",
        type: "circle",
        source: "circles",
        filter: ["has", "point_count"],
        paint: {
          "circle-color": [
            "step",
            ["get", "point_count"],
            "#51bbd6",
            100,
            "#f1f075",
            750,
            "#f28cb1",
          ],
          "circle-radius": [
            "step",
            ["get", "point_count"],
            20,
            100,
            30,
            750,
            40,
          ],
        },
      });

      // cluster count
      map.current.addLayer({
        id: "cluster-count",
        type: "symbol",
        source: "circles",
        filter: ["has", "point_count"],
        layout: {
          "text-field": ["get", "point_count_abbreviated"],
          "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
          "text-size": 12,
        },
      });

      // markers
      map.current.loadImage(pin, (error, image) => {
        if (error) throw error;
        map.current.addImage("pin", image);
        map.current.addLayer({
          id: "unclustered-point",
          zomm: 5,
          type: "symbol",
          source: "circles",
          filter: ["!", ["has", "point_count"]],
          layout: {
            "icon-image": "pin",
            "icon-size": 0.0375,
          },
        });
      });

      // cluster click & auto zooms
      map.current.on("click", "clusters", (e) => {
        const features = map.current.queryRenderedFeatures(e.point, {
          layers: ["clusters"],
        });
        const clusterId = features[0].properties.cluster_id;
        map.current
          .getSource("circles")
          .getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err) return;

            map.current.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoom,
            });
          });
      });

      // markers & popups
      map.current.on("click", "unclustered-point", (e) => {
        console.log();
        const coordinates = e.features[0].geometry.coordinates.slice();
        let title = e.features[0].properties.title;
        let description = e.features[0].properties.description;

        const id = e.features[0].properties.id;

        if (title.length >= 48) {
          title = title.slice(0, 48) + "...";
        }
        if (description.length > 76) {
          description = description.slice(0, 76) + "...";
        }

        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        const customBtn = `<button type="button" 
          class='btn btn-info' 
          style="margin-top:6px; font-size: 12px; color: #fff; border: none; background-color: #2BB24B; 
          padding: 6px 12px; border-radius: 16px; font-color:white; cursor: pointer;" 
          value=${id} id='viewDetail'>
            View Details
          </button>`;

        const popuper = new mapboxgl.Popup({})
          .setMaxWidth("300px")
          .setLngLat(coordinates)
          .setHTML(
            `
            <div style="padding: 6px">
            <h1 style="text-align: left; font-weight:700; font-size:18px; color: #2BB24B;">${title}</h1>
                <h3 style="font-weight:500; font-size: 14px; color: #ddd;">Field #1</h3>
            <p style="text-align: left; font-size: 14px; margin-top: 4px; color: #A6B6B7;">${description}</p>
            ${customBtn}
            </div>
            `
          )
          .addTo(map.current);

        document.getElementById("viewDetail").addEventListener("click", (e) => {
          if (e) {
            setSearchParams({ project: e.target.attributes.value.value });
          }
          popuper.remove();
        });
      });

      // polygon fill
      map.current.addLayer({
        id: "polygon-fill",
        type: "fill",
        source: mapDataPolygons,
        paint: {
          "fill-color": "#568203",
          "fill-opacity": 0.25,
        },
        filter: ["==", "$type", "Polygon"],
      });

      // polygon outline
      map.current.addLayer({
        id: "polygon-outline",
        type: "line",
        source: mapDataPolygons,
        layout: {},
        paint: {
          "line-color": "#2bb24b",
          "line-width": 5,
        },
        filter: ["==", "$type", "Polygon"],
      });

      map.current.addLayer({
        id: "polygon-fill-other-fields",
        type: "fill",
        source: mapDataPolygonsOtherFields,
        paint: {
          "fill-color": "#568203",
          "fill-opacity": 0.25,
        },
        filter: ["==", "$type", "Polygon"],
      });

      map.current.addLayer({
        id: "polygon-outline-other-fields",
        type: "line",
        source: mapDataPolygonsOtherFields,
        layout: {},
        paint: {
          "line-color": "#2bb24b",
          "line-width": 2,
        },
        filter: ["==", "$type", "Polygon"],
      });

      map.current.on("click", "polygon-fill-other-fields", (e) => {
        const obj = {
          projectName: e.features[0].properties.projectName,
          id: e.features[0].properties.id,
          name: e.features[0].properties.name,
          area: e.features[0].properties.area,
        };

        new mapboxgl.Popup()
          .setLngLat(e.lngLat)
          .setHTML(
            `
            <div style="padding: 6px">
            <h1 style="text-align: left; font-weight:700; font-size:18px; color: #2BB24B;">${obj.projectName}</h1>
                <h3 style="font-weight:500; font-size: 14px; color: #ddd;">Field #${obj.id}</h3>
            <p style="text-align: left; font-size: 14px; margin-top: 4px; color: #A6B6B7;">Area: ${obj.area}</p>
            </div> 
            `
          )
          .addTo(map.current);
      });

      // mouse cursor
      map.current.on("mouseenter", "clusters", () => {
        map.current.getCanvas().style.cursor = "pointer";
      });
      map.current.on("mouseleave", "clusters", () => {
        map.current.getCanvas().style.cursor = "";
      });
    });
  }, []);

  useEffect(() => {
    const data = searchParams.get("project");
    setSelectedData(
      serializedMapData.features.find(
        (item) => item.properties.id === searchParams.get("project")
      )
    );
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (selectedData) {
      flyIn();
    } else {
      // window.scrollTo({ top: 0, behavior: "smooth" });
      flyOut();
    }
  }, [searchParams, selectedData]);

  function flyIn() {
    if (selectedData?.bounds) {
      map.current.fitBounds(selectedData?.bounds, { padding: 50 });
    }
  }

  function flyOut() {
    map.current.flyTo({
      center: [83, 28],
      zoom: 1,
      essential: true, // this animation is considered essential with respect to prefers-reduced-motion
    });
  }

  function setProjectId(id) {
    setSearchParams({ project: id });
  }

  function clearProjectId() {
    setSearchParams({});
  }

  return (
    <div className="relative lg:static">
      <div className="grid grid-cols-2">
        {searchParams.get("project") ? (
          <ProjectDetails
            cn={`col-span-2 order-2 md:order-1 md:col-span-1 md:h-[calc(100vh-80px)] overflow-y-auto`}
            data={selectedData}
            clearProjectId={clearProjectId}
          />
        ) : (
          <Projects
            cn="col-span-2 order-2 md:order-1 md:col-span-1 md:h-[calc(100vh-80px)] overflow-y-auto"
            data={serializedMapData}
            setProjectId={setProjectId}
          />
        )}

        <div
          ref={mapContainer}
          className="order-1 col-span-2 md:col-span-1 md:order-2 map-container h-[40vh] md:h-[calc(100vh-80px)] "
        ></div>
      </div>
    </div>
  );
}
